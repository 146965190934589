.point-page #checkout-alert {
  display: none;
  font-size: 1rem;
  line-height: 3rem;
  text-align: center;
  margin-bottom: 10px;
  border: 1px solid;
  border-radius: 10px;
  position: relative;
}
.point-page #checkout-alert.error {
  color: #fff !important;
  border-color: #f00;
  background-color: #f00;
}
.point-page #checkout-alert.loading {
  color: #fff !important;
  background-color: #0ca101;
  border-color: #0ca101;
}
.point-page #checkout-alert.loading:before {
  width: 2.9rem;
  height: 2.9rem;
  content: '';
  display: block;
  position: absolute;
  left: 0;
  background: url('https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/svg/loading.svg') 50%
    50% no-repeat;
}
.point-page #checkout-alert.show {
  display: block;
}
.point-page #checkout-license-checkbox.error {
  color: red;
}
.point-page .point-charge-item-inner.error {
  background-color: #ff7676;
}

.point-page .icon-cash-blue {
  display: inline-block;
  background-image: url('/public/assets/img/SVG/icon-cash-blue.svg');
  width: 24px;
  height: 24px;
  margin-right: 4px;
}

.point-page .icon-cash-black {
  display: inline-block;
  background-image: url('/public/assets/img/SVG/icon-cash-black.svg');
  width: 14px;
  height: 14px;
  margin-right: 0.83px;
}

@media screen and (max-width: 768px) {
  .point-page .icon-cash-blue {
    width: 21px;
    height: 21px;
    margin-right: 2px;
    background-size: 21px 21px;
  }
}
