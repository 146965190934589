.checkout-page .warn-3c9a {
  color: #ff0000;
}

.checkout-page .purchase-product-list-item.already-bought-3c3a {
  opacity: 0.5;
}

.checkout-page #checkout-alert {
  display: none;
  font-size: 1rem;
  line-height: 3rem;
  text-align: center;
  margin-bottom: 10px;
  border: 1px solid;
  border-radius: 10px;
  position: relative;
}

.checkout-page #checkout-alert.error {
  color: #fff !important;
  border-color: #f00;
  background-color: #f00;
}

.checkout-page #checkout-alert.loading {
  color: #fff !important;
  background-color: #0ca101;
  border-color: #0ca101;
}

.checkout-page #checkout-alert.loading:before {
  width: 2.9rem;
  height: 2.9rem;
  content: '';
  display: block;
  position: absolute;
  left: 0;
  background: url('https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/svg/loading.svg') 50%
    50% no-repeat;
}

.checkout-page #checkout-alert.show {
  display: block;
}

.checkout-page .payment-discount {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  gap: 30px;
}

.checkout-page #applyCode {
  margin-top: 10px;
}

@media (max-width: 800px) {
  .checkout-page .payment-discount {
    flex-direction: column;
  }
}

.payment-block-body .my-point input::placeholder {
  color: #c4c4c4;
  font-size: 12px;
  font-weight: 400;
}

.tippy-icon-questionmark {
  background-image: url('/public/assets/img/SVG/icon-questionmark.svg');
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-top: 1px;
  margin-left: 10px;
  cursor: pointer;
}

.tippy-container {
  display: flex;
}
