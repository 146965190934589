.fab-page-section-ko,
.fab-page-section-ko .--font-basic,
.fab-page-section-ko .font-nanumsquare,
.fab-page-section-ko h1,
.fab-page-section-ko h2,
.fab-page-section-ko h3,
.fab-page-section-ko h4 {
  letter-spacing: -0.07em;
  font-family: Roboto, Noto Sans KR, -apple-system, system-ui,
    BlinkMacSystemFont, "Segoe UI", sans-serif;
}
