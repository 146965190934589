.review-create .selectize-dropdown,
.selectize-input {
  font-size: 16px;
  line-height: 23px;
}

.review-create .selectize-input,
.review-create .selectize-input.focus {
  border: none;
  box-shadow: none;
}

.review-create .selectize-control.form-input {
  padding: 0;
}

.review-create .selectize-control.multi .selectize-input > div {
  background-color: #0ca101;
  color: white;
  font-weight: 400;
}

.review-create .selectize-dropdown [data-selectable] .highlight {
  background-color: #00c900;
  color: #fff;
}

.review-create .selectize-dropdown .active {
  background-color: #00c900;
  color: #fff;
}

.selectize-input {
  display: flex;
  align-items: center;
  height: 100%;
}
