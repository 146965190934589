.free-limit-101f {
    font-size: 0.75rem;
}

.free-limit-101f-first {
    margin-top: 1rem;
}

.free-limit-102f {
    color: #4e00d2;
    font-size: 0.9rem;
}

.free-limit-102f-first {
    margin-top: 0.5rem;
}

.free-limit-102f b.free-limit-103f {
    font-size: 2.5rem;
}