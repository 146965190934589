.profile-page .btn-download {
  background: #ff6a10;
  color: #fff !important;
}
.profile-page .from-manage-printer-list {
  padding-bottom: 2rem;
  margin-bottom: 2.25rem;
  border-bottom: 1px solid #ccc;
}
.profile-page #my-printer-list {
  border: 0px;
  margin-bottom: 0rem;
}
