input.request-search {
  width: 9rem;
  border: 0;
  height: 2rem;
  background: 0 0;
  border-bottom: 0 solid #000;
  border-bottom-width: 1px;
}
a.request-search {
  display: inline-block;
  width: 2rem;
  height: 1rem;
}
