.vc {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}
.vc iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.test {
  margin-top: 1.25rem;
}
.swiper-container-105 {
  overflow: visible;
}
