.checkout-confirm-page .warn-3c9a {
  color: #ff0000;
}
.checkout-confirm-page .purchase-product-list-item.already-bought-3c3a {
  opacity: 0.5;
}
.checkout-confirm-page #checkout-alert {
  display: none;
  font-size: 1rem;
  line-height: 3rem;
  text-align: center;
  margin-bottom: 10px;
  border: 1px solid;
  border-radius: 10px;
  position: relative;
}
.checkout-confirm-page #checkout-alert.error {
  color: #fff !important;
  border-color: #f00;
  background-color: #f00;
}
.checkout-confirm-page #checkout-alert.loading {
  color: #fff !important;
  background-color: #0ca101;
  border-color: #0ca101;
}
.checkout-confirm-page #checkout-alert.loading:before {
  width: 2.9rem;
  height: 2.9rem;
  content: '';
  display: block;
  position: absolute;
  left: 0;
  background: url('https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/svg/loading.svg') 50%
    50% no-repeat;
}
.checkout-confirm-page #checkout-alert.show {
  display: block;
}
.checkout-confirm-page #checkout-button-container-wrapper {
}
.checkout-confirm-page .checkout-button-container-loading {
  display: none;
  -webkit-transition: none;
  -ms-transition: none;
  -o-transition: none;
  background: transparent
    url('https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/svg/loading.color.svg') center
    center no-repeat !important;
  opacity: 0.5;
  cursor: wait;
  width: 100%;
  height: 3rem;
}
.checkout-confirm-page
  #checkout-button-container-wrapper.loading
  .checkout-button-container-loading {
  display: block;
}
.checkout-confirm-page #checkout-button-container-wrapper.loading .checkout-button-container {
  display: none;
}
.checkout-confirm-page .checkout-button-container {
  display: none;
}
.checkout-confirm-page .checkout-button-or {
  line-height: 2rem;
  text-align: center;
}
.checkout-confirm-page .paypal-problem {
  margin-bottom: 20px;
}
.checkout-confirm-page .paypal-problem p {
  margin-bottom: 10px;
}
.checkout-confirm-page .paypal-problem p .email {
  color: #4200f8;
}
.checkout-confirm-page .payment-select-container {
  display: block;
  line-height: 2rem;
}
.checkout-confirm-page .payment-select-container.disabled {
  color: #777;
}

.checkout-confirm-page #stripe-form .hidden {
  display: none;
}

.checkout-confirm-page #stripe-form button {
  background: #5469d4;
  color: #ffffff;
  font-family: Arial, sans-serif;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
.checkout-confirm-page #stripe-form button:hover {
  filter: contrast(115%);
}
.checkout-confirm-page #stripe-form button:disabled {
  opacity: 0.5;
  cursor: default;
}
.checkout-confirm-page #stripe-form #stripe-card-error {
  color: rgb(105, 115, 134);
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}

.checkout-confirm-page #stripe-form #stripe-card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  width: 93%;
  background: white;
}

.checkout-confirm-page #stripe-form .spinner,
.checkout-confirm-page #stripe-form .spinner:before,
.checkout-confirm-page #stripe-form .spinner:after {
  border-radius: 50%;
}
.checkout-confirm-page #stripe-form .spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.checkout-confirm-page #stripe-form .spinner:before,
.checkout-confirm-page #stripe-form .spinner:after {
  position: absolute;
  content: '';
}
.checkout-confirm-page #stripe-form .spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.checkout-confirm-page #stripe-form .spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.checkout-confirm-page #stripe-form .result-message {
  line-height: 22px;
  font-size: 16px;
}

.checkout-confirm-page #stripe-form .stripe-container {
  display: none;
}
.checkout-confirm-page .icon-pay {
  display: inline-block;
  height: 1.5rem;
  line-height: 2rem;
  vertical-align: middle;
  margin-left: 0.5rem;
}

.txt-limit {
  font-size: 0.8rem;
  margin-right: 1rem;
  vertical-align: middle;
}
