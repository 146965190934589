.license-page .fabj-license-agreement {
  line-height: 1.8;
  margin-bottom: 1rem;
}

.license-page .fabj-license-agreement b {
  font-weight: 600;
}
.license-page #checkout-license-checkbox.error {
  color: red;
}
